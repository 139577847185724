.homepage #about,
.homepage #chirurgiens,
.homepage #actualites,
.homepage #honoraires{
    scroll-margin-top: calc(var(--header-height)*2);
}

.homepage_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
}
.homepage_content picture {
    width:  100%;
    height: 100vh;
}
.homepage_content picture img {
    object-fit: cover;
}
.homepage_content .content_banner {
    position: absolute;
    bottom: var(--header-height);
    width: 90%;
    margin: auto;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    color: var(--light);
}
.homepage_content .content_banner svg{
    fill: none;
}
.homepage_content .content_banner a{
    border: 1px solid var(--light);
    border-radius: 100px;
    padding: 10px;
    margin-left: 20px;
}


/* about */

section#about{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
#about picture{
    height: 100%;
    width: 100%;
    position: absolute;
}
#about picture img{
    object-fit: cover;
}
#about .content_about{
    width: 45%;
    /*min-height: 80vh;*/
    background-color: var(--light);
    padding: var(--container-padding);
    margin: var(--container-padding) 0;
}


/* chirurgiens */

section#chirurgiens .top, section#actualites .top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: var(--gap-padding);
}
section#chirurgiens .top .h2, section#actualites .top .h2{
    margin-bottom: 0;
}


/* honoraires */

section#honoraires{
    background-color: var(--secondary);
    padding: var(--container-padding);
    display: flex;
    justify-content: center;
}
section#honoraires .paragraphe{
    max-width: 50em;
}
section#honoraires .h2{
    text-align: center;
}


@media screen and (max-width: 800px) {

    section#about{
        flex-direction: column-reverse;
    }
    section#about .content_about{
        width: 100%;
        height: 100%;
        background-color: transparent;
    }
    section#about picture{
        opacity: 0.1;
    }
    section#honoraires .paragraphe {
        padding: var(--container-padding) 0;
    }
}